import styled from "styled-components";

export const Wrapper = styled.header`
  position: relative;
  display: flex;
  align-content: center;
  justify: center;
  background-color: black;
`;
export const WrapperColored = styled(Wrapper)`
  background-image: ${props => props.theme.background.gradient};
`;

export const Container = styled.div`
  max-width: 1110px;
  width: 100%;
  margin: auto;
  color: white;

  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 16px;
    max-width: 95%;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1110px;
  }
`;
export const ContainerColored = styled(Container)`
  color: white;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 2em;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 1fr;
    padding: 0 1em;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-template-columns: 1fr;
    padding: 0px;
  }
`;

export const WhiteButton = styled.button`
  color: ${props => props.theme.color.secondary};
  background: ${props => props.theme.color.button};
`;
export const BlueButton = styled.button`
  color: ${props => props.theme.color.button};
  background: ${props => props.theme.color.secondary};
`;

export const FlexBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;

  @media (max-width: ${props => props.theme.screen.lg}) {
    padding: 0 1em;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 0px;
  }
`;
export const Title = styled.h4`
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-left: 0px;
  padding-right: 16px;
  color: ${props => props.theme.color.text.onWhite};
`;
export const Subtitle = styled.h3`
  max-width: 712px;
  padding-left: 0px;
  padding-right: 16px;
  margin-bottom: 64px;
  position: relative;
  color: ${props => props.theme.color.text.onWhite};
  font-family: ${props => props.theme.font.medium};
`;

export const Accent = styled.span`
  color: #faed25;
`;
