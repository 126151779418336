import React from "react";
import styled from "styled-components";
import { Container, Wrapper } from "../global";
import { Typography } from "@material-ui/core";
import { TheCourseURL } from "../common/navigation/courses";

const IncludeBrainTestsLink = false;

const Footer: React.FC = () => (
  <FooterWrapper id="footer">
    <FooterContainer>
      <FooterColumnContainer>
        <FooterColumn>
          <span>
            <Typography variant="h3">CPLABS</Typography>
          </span>
        </FooterColumn>
        <FooterColumn>
          <a href="/about">About</a>
        </FooterColumn>
        <FooterColumn>
          <a href={TheCourseURL}>The Course</a>
        </FooterColumn>
        <FooterColumn>
          <a href="https://akomaps.com/">Ako Maps</a>
        </FooterColumn>
        {IncludeBrainTestsLink && (
          <FooterColumn>
            <a href="https://akomaps.com/app/profile/braintests">Brain Tests</a>
          </FooterColumn>
        )}
      </FooterColumnContainer>
      <Copyright>
        <span>Copyright &copy; 2021 - {new Date().getFullYear()} CPLabs</span>
        <a href="https://akomaps.com/legal/terms-akomaps">Terms and conditions</a>
        <a href="https://akomaps.com/legal/privacy-policy">Privacy policy</a>
      </Copyright>
    </FooterContainer>
  </FooterWrapper>
);
const FooterWrapper = styled(Wrapper)`
  background-image: linear-gradient(to bottom, #000, 50%, #282828);
  -webkit-text-fill-color: ${props => props.theme.color.link.nav};
  & a {
    color: ${props => props.theme.color.link.nav};
  }
`;

const FooterContainer = styled(Container)`
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
`;

const FooterColumnContainer = styled(Container)`
  display: grid;
  padding: 0 1em;
  grid-template-columns: repeat(${IncludeBrainTestsLink ? 5 : 4}, 1fr);
  grid-column-gap: 32px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 8px;
  }
`;
const FooterColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;
const Copyright = styled.div`
  ${props => props.theme.fontSize.xxxsmall};
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2em 1em 0;
  display: flex;
  flex-flow: row wrap;
  > a,
  > span {
    margin-right: 20px;
  }
`;

export default Footer;
