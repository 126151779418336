/* eslint-disable prettier/prettier */
import styled from "styled-components"
import { Grid as _Grid } from "@material-ui/core";

export const Nav = styled.nav`
  padding: 8px 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
  background: transparent;
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0;
    background-image: none;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0 16px;
  }
`
export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${(props: { mobile: boolean }) =>
    props.mobile &&
    `
        flex-direction: column;
        margin-top: 0;

        > ${NavItem} {
          text-align:center;
          padding-top: 1em;
          padding-bottom: 1em;
          
        }
      `};
  }
`

export const NavItem = styled.li`
  padding: 0 24px;
  ${props => props.theme.fontSize.small};
  letter-spacing: 1px;
  a {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0 16px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    &:hover {
      color: rgba(0, 0, 0, 0.2);
    }
    ${props => props.theme.fontSize.xsmall};
    a {
      ${props => props.theme.fontSize.regular}
    }
    a:hover {
      color: #faed25;
    }
    > span {
      width: 100%;
    }
  }

  > span > a {
    text-decoration: none;
    -webkit-text-fill-color: ${props => props.theme.color.link.nav};
    color: ${props => props.theme.color.link.nav}; 
  }
`

export const Brand = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.fontSize.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  padding: 0 24px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    a {
      color: ${props => props.theme.color.black.regular};
      text-decoration: none;
    }
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 0;
  }
`
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }

  button {
    ${props => props.theme.fontSize.xsmall};
    color: white;
    background: #098b8c;
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
  }
`

export const Mobile = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
  }
  ${(props:{hide: boolean, theme: any}) =>
    props.hide &&
    `
    display: block;
    @media (max-width: ${props.theme.screen.sm}) {
      display: none;
    }
  `}
`
export const NavList = styled.ul`
    align-items:center;
`

export const Grid = styled(_Grid)`
  background-color: #1c1c1c;
  padding: 32px 0 32px;
  box-shadow: 0px 5px #000;
`