import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const initTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0055D7",
    },
    secondary: {
      main: "#0C017A",
    },
  },
  typography: {
    fontFamily: ["Bebas Neue", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: 150,
      "@media (max-height:767px)": {
        lineHeight: "0.9",
      },
      "@media (max-height:500px)": {
        fontSize: "4rem!important",
      },
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    },
    h6: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
      fontWeight: 300,
      letterSpacing: "5px",
      "@media (max-width:350px)": {
        letterSpacing: "4px",
      },
    },
    body1: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    },
  },
});

const theme = responsiveFontSizes(initTheme);

export default theme;
